import * as React from 'react';
import Layout from '../../components/Layout';
import Intro_Logo_svg from '../../images/1. Brand Elements/Intro_Logo.svg';
import empty_darker_png from '../../images/logo/empty-darker.png';
import empty_lighter_png from '../../images/logo/empty-lighter.png';
import Intro_Typography_svg from '../../images/1. Brand Elements/Intro_Typography.svg';

import PrevNextNavFooter from '../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter';

// Brand elements seems to be a section header (not a clickable menu item)
const BrandElements = props=>{
    return (
        <Layout>

            <PrevNextNavFooter nextText='Next' nextHeader='Photography' nextUrl='/brandguidelines/photography'/>
        </Layout>
    );
}

export default BrandElements;
